.react-chatbot-kit-chat-container {
  /*
  width: 100% !important;
  width: -moz-available !important;
  width: -webkit-fill-available !important;
  width: fill-available !important;
  */
  /*height: -webkit-fill-available;*/
  /*min-height: calc(var(--vh, 1vh) * 100);*/
  /*height: calc(100%-100px);*/

  width: calc(var(--vw, 1vw) * 100) !important;
  height: calc(100%) !important;
}

.react-chatbot-kit-chat-inner-container {
  height: 200px;
  margin-bottom: 0px;
  height: calc(100%) !important;
}

.react-chatbot-kit-chat-message-container {
  height: 100px;
  height: calc(100% - 100px) !important;
}

.react-chatbot-kit-chat-container input:focus {
  outline-width: 0;
  outline: none;
}

.react-chatbot-kit-chat-container button:focus {
  outline-width: 0;
  outline: none;
}
